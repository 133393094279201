export class SfCssModule {
	url;
	domNode;

	constructor(stylesheetUrl) {
		//console.log('Loading new CSS module: ' + stylesheetUrl);
		this.url = stylesheetUrl;

		this.domNode = document.createElement('link');
		this.domNode.rel = 'stylesheet';
		this.domNode.href = this.url;

		document.head.appendChild(this.domNode);
	}

	destroy() {
		document.head.removeChild(this.domNode);
	}
}
