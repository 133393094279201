export class SfModuleRegistry {
	static css = [];
	static js = [];

	static add(type, el) {
		if (type === 'css') {
			this.css.push(el);
		} else if (type === 'js') {
			this.js.push(el);
		}
	}

	// TODO finish implementing
	static remove(type, url) {
		let el = this.get(type, url);
		console.log(`El equals: ${el}`);
		console.log(el);
		if (el) {
			console.log(el);
			el.destroy();
			// TODO Splice the item from the list
		}
	}

	static get(type, url) {
		let el;

		if (type === 'css') {
			for (const cssEl of this.css) {
				if (cssEl.url === url) {
					el = cssEl;
				}
			}
		} else if (type === 'js') {
			for (const jsEl of this.js) {
				if (jsEl.url === url) {
					el = jsEl;
				}
			}
		}

		return el;
	}
}
