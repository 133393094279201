import {SfModuleLoader} from "./SfModuleLoader.js";

export class SfBootstrapper {
	instances = [];

	constructor() {
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', this.init);
		} else {
			this.init();
		}

		window.SfBootstrapper = this;
	}

	init() {
		let lang;
		let country;
		let culture;
		
		
		country = window.jenkon.country;
		culture = window.jenkon.culture;
		 

		if(culture.indexOf('-') > -1) {
			let setLanguage = culture;
			//lang = setLanguage.split('-')[0];
			if (setLanguage.toLowerCase() == "en-us") {
				setLanguage = "en";
			}
			lang = setLanguage;
		}

		
		document.querySelectorAll('div[data-rel="sf-page-container"]').forEach(rootNode => {
			let route = rootNode.getAttribute('data-route');
			let apiKey = rootNode.getAttribute('data-apikey');
			let apiUrl = rootNode.getAttribute('data-url');
			let site = rootNode.hasAttribute('data-site') ? rootNode.getAttribute('data-site') : (window.jenkon.country === "ES" || window.jenkon.country === "PT" ? "iberia" : window.jenkon.country);

			//route comes from a data-attribute on the loading div, site and lang variables come from jenkon.
			if (route && site && lang && apiKey) {
				new SfModuleLoader(rootNode, route, lang, site, apiKey, apiUrl);
			} else {
				console.log('Route value must be specified on the data-attributes of the loading div. Failed to push instances of SFModuleLoader');
			}
		});
	}
}
