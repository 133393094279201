export class SfJavascriptModule {
	url;
	moduleName;
	domNode;
	moduleNameNoCache;

	constructor(scriptUrl) {
		//console.log('Loading new JS module: ' + scriptUrl);
		this.url = scriptUrl;

		console.log(scriptUrl);

		const urlSegments = scriptUrl.split('/');
		const moduleName = urlSegments[urlSegments.length - 1].replace('.js', '');
		let moduleNameNoCachePreSplit;
		this.moduleName = `TPWR.${moduleName}`;


		if(this.moduleName.includes("?cachebust")) {
			let urlSplit = this.moduleName.split("?cachebust");
			moduleNameNoCachePreSplit = urlSplit[0];
			this.moduleNameNoCache = moduleNameNoCachePreSplit.split('.')[1];
		} else if(this.moduleName.includes("&cachebust") > -1) {
			let urlSplit = this.moduleName.split("&cachebust");
			moduleNameNoCachePreSplit = urlSplit[0];
			this.moduleNameNoCache = moduleNameNoCachePreSplit.split('.')[1];
		} else {
			this.moduleNameNoCache = this.moduleName;
		}


		this.domNode = document.createElement('script');
		this.domNode.type = 'text/javascript';
		this.domNode.src = this.url;

		//checkand see if the script is already loaded, if so dont load it again
		if(!this.alreadyLoaded(this.domNode.src)){
			document.body.appendChild(this.domNode);
		} else {
			SfJavascriptModule.rerunInit(this.moduleNameNoCache);
		}
	}

	static rerunInit(scriptName) {
		let fn;
		fn = window.TPWR && window.TPWR.hasOwnProperty(`${scriptName}`) ? window.TPWR[`${scriptName}`] : null;

		if(fn && fn.hasOwnProperty('init')){
			fn = window.TPWR[`${scriptName}`]["init"];
		}

		if (fn && typeof fn === "function") {
			fn();
		}
	}

	alreadyLoaded(url) {
		let scriptTags = document.querySelectorAll('script');
		let alreadyLoaded = false;
		let scriptArray = [];

		scriptTags.forEach((scriptTag) => {
			if(!scriptTag.hasAttribute('src')) {
				return
			}
			let src = scriptTag.getAttribute("src");
			scriptArray.push(src);
		});

		let noCacheUrl = null;
		if(url.includes("?cachebust")) {
			let urlSplit = url.split("?cachebust");
			noCacheUrl = urlSplit[0];
		} else if(url.includes("&cachebust") > -1) {
			let urlSplit = url.split("&cachebust");
			noCacheUrl = urlSplit[0];
		} else {
			noCacheUrl = url;
		}

		if(scriptArray.length) {
			scriptArray.forEach((item) => {
				if(item.includes(noCacheUrl)) {
					alreadyLoaded = true;
				}
			});
		}

		return alreadyLoaded;
	}

	destroy() {
		document.body.removeChild(this.domNode);
	}

	getModuleRef() {
		let md = null;
		if(this.moduleName.includes("?cachebust")) {
			let mdSplit = this.moduleName.split("?cachebust");
			md = mdSplit[0];
		} else if(this.moduleName.includes("&cachebust") > -1) {
			let mdSplit = this.moduleName.split("&cachebust");
			md = mdSplit[0];
		} else {
			md = this.moduleName;
		}

		return this.getNamespacedObject(md);
	}

	// Internal helper function
	// @private
	getPackageArray = function (pkg) {
		return (typeof(pkg) !== 'string') ? '' : pkg.split('.');
	};

	// Internal helper function
	// @private
	getNamespacedObject(pkg) {
		let packageParts = this.getPackageArray(pkg),
			target = window,
			nextPart,
			retVal = null;

		for (let i = 0, max = packageParts.length; i < max; i++) {
			nextPart = packageParts[i];
			if (typeof (target[nextPart]) === 'undefined') {
				retVal = false;
				break;
			}
			retVal = target = target[nextPart];
		}

		return retVal;
	};
}
