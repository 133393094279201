import {SfCssModule} from "../TUP/SfCssModule.js";
import {SfJavascriptModule} from "../TUP/SfJavascriptModule.js";
import {SfModuleRegistry} from "../TUP/SfModuleRegistry.js";
import "objectFitPolyfill";
import 'whatwg-fetch';

//const NEW_API_BASE_URL = 'https://dev-content.tupperware.com/api/page';

export class SfModuleLoader {
	rootNode;
	origRoute;
	route;
	lang;
	site;
	apikey;
	apiurl;
	routeData;
	previousRoute;
	routeRetry = 0;

	constructor(rootNode, route, lang, site, apikey, apiurl) {
		this.origRoute = route;
		
		const hash = location.hash;
		if (hash && hash.indexOf('#sf:') === 0) {
			this.route = hash.replace('#sf:', '');
		} else {
			this.route = route;
		}

		this.rootNode = rootNode;
		this.apiurl = apiurl;
		this.lang = lang;
		this.apikey = apikey;
		this.site = site;
		console.log(this.site);
		

		window.addEventListener('popstate', (e) => {
			let newRoute = location.hash.substring(1).replace('sf:', '');
			if (newRoute !== this.route) {
				this.loadRoute(newRoute);
			}
		});

		console.log(this.apikey);
		console.log(this.route);
		this.loadRoute(this.route);
	}

	async loadRoute(route) {
		// Failsafe: If we can't load the desired route, fall back to whatever was in data-route
		if (!route) {
			route = this.origRoute;
		}
		
		this.route = route;

		// Maybe we need a loading indicator while this is happening?
		const newUrl = `${this.apiurl}/api/page?site=${this.site}&language=${this.lang}&path=${this.route}`;

		// Call API
		await this.getAPIResults(newUrl);
	}

	showHideLoading(show) {
		let tpp = this.rootNode.querySelector('.tpp');

		if (tpp) {
			if (show) {
				window.scrollTo(0, 0);
				tpp.classList.add('js-is-loading');
			}
			else {
				if (this.rootNode){
					if (tpp.classList.contains('js-is-loading')) {
						tpp.classList.remove('js-is-loading');
					}
				}
			}
		}
	}

	handleLoadError(e) {
		// Do something constructive
		// We definitely need an "Unable to load" error message
	}

	async initRoute(newRoute) {
		this.rootNode.removeAttribute('style');
		if(this.routeData) {
			this.cleanup(this.routeData, newRoute);
		}

		this.rootNode.innerHTML = '';
		this.routeData = newRoute;
		this.rootNode.innerHTML = this.routeData.html;

		if (this.routeData.hasOwnProperty('css')) {
			// console.log('Initializing CSS modules');
			for (let stylesheetUrl of this.routeData.css) {
				// Check to see if it's already in the registry
				// If not, load it and add it
				if (!SfModuleRegistry.get('css', stylesheetUrl)) {
					const stylesheet = new SfCssModule(stylesheetUrl);
					SfModuleRegistry.add('css', stylesheet);
				} else {
					// console.log('CSS module already exists: ' + stylesheetUrl);
				}
			}
		}

		if (this.routeData.hasOwnProperty('js')) {
			// console.log('Initializing JS modules');
			for (let scriptUrl of this.routeData.js) {
				// Check to see if it's already in the registry
				// If not, load it and add it
				//console.log(scriptUrl);

				const nextModule = SfModuleRegistry.get('js', scriptUrl);
				if (!nextModule) {
					const script = new SfJavascriptModule(scriptUrl);
					SfModuleRegistry.add('js', script);
				} else {
					// console.log('JS module already exists: ' + scriptUrl);
					let module = nextModule.getModuleRef();
					if (module) {
						// We assume that every module created has an init() and a destroy() method
						module.init();
					}
				}
			}
		}

		// remove our load indicator after everything is finished.
		this.showHideLoading(false);
		
		//after everything loads scroll the window to the top
		window.scrollTo(0, 0);

		//reinitialize our link handler to grab the correct links with the class .js-api-link
		this.initSearchHandler();
		this.initializeTags();
		this.initializeCategories();
		this.initLinkHandler();

		this.initVariableReplace();
		objectFitPolyfill();
	}

	initVariableReplace() {
		let getTodaysDate = () => {
			const monthNames = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
			];
			let today = new Date();
			let dd = String(today.getDate()).padStart(2, '0');
			let mm = String(monthNames[today.getMonth()]); //January is 0!
			let yyyy = today.getFullYear();

			today = `${mm} ${dd}, ${yyyy}`;
			return today;
		};

		let replaceableVars = {
			//name: window.consultant.name ? window.consultant.name : '',
			date: getTodaysDate(),
		};

		//look for any variables in the object and replace them with their values;
		const entries = Object.entries(replaceableVars);
		for (const [prop, value] of entries) {
			let varGroup = document.querySelectorAll(`.tpp-var-${prop}`);

			if(varGroup.length) {
				varGroup.forEach((item) => {
					item.innerHTML = value;
					let parent = item.parentNode;
					while (item.firstChild) parent.insertBefore(item.firstChild, item);
					parent.removeChild(item);
				});
			}
		}
	}

	initLinkHandler() {
		//changed this to only target the links we want it to, not everything.
		let deepLinks = this.rootNode.querySelectorAll('.js-api-link[href]');
		let $this = this;

		let attachEvent = (evt) => {
			let orgRoute = evt.currentTarget.getAttribute('href');
			let newRoute;
			if(orgRoute.indexOf("#sf:") > -1) {
				newRoute = orgRoute.replace('#sf:', '');
			} else {
				newRoute = orgRoute;
			}

			history.pushState({}, '', orgRoute);
			$this.loadRoute(newRoute);
		};
		console.log(deepLinks);

		deepLinks.forEach((link) => {
			let urlRemainder;
			let url = link.getAttribute('href');
			console.log('url ' + url);
			if(url && url.includes(`/${this.site}/`)) {
				urlRemainder = url.split(`/${this.site}/`)[1]
			} else if(url && url.includes(`/${this.site}`)) {
				urlRemainder = url.split(`/${this.site}`)[1]
			}

			let theUrl;
			
			if(urlRemainder && urlRemainder.includes(this.lang.toLowerCase())) {
				theUrl = urlRemainder.replace(`${this.lang.toLowerCase()}/`, '');
			} else {
				theUrl = urlRemainder;
			}
			console.log(theUrl);
			link.setAttribute('href', `#sf:/${theUrl}`);

			link.removeEventListener('click', attachEvent, false);
			link.addEventListener('click', attachEvent, false);
		});
	}

	initSearchHandler() {
		let searches = this.rootNode.querySelectorAll('.js-api-search');
		let $this = this;
		let clickEventListenerBound = false;

		if(!searches.length){
			return;
		}

		let searchClickEvent = (evt) => {
			let parentContainer = $this.findParentBySelector(evt.currentTarget, '.js-api-search');

			// Execute search
			this.loadSearch(parentContainer);
			clickEventListenerBound = true;
		};

		// SAYT
		let loadSayt = (evt) => {
			let parentContainer = $this.findParentBySelector(evt.currentTarget, '.js-api-search');

			if (evt.keyCode === 13) {				
				let closestButton = parentContainer.querySelector('.js-searchbutton');
				closestButton.click();
			} else if (evt.currentTarget.value.length > 2) {
				let value = evt.currentTarget.value;
				let currTarget = evt.currentTarget;
				let indexCatalogue = parentContainer.querySelector('input[data-sf-role="indexCatalogue"]').value;
				let suggestionFields = parentContainer.querySelector('input[data-sf-role="suggestionFields"]').value;

				const url = `${this.apiurl}/api/search/suggestions?site=${this.site}&language=${this.lang}&indexCatalogue=${indexCatalogue}&suggestionFields=${encodeURI(suggestionFields)}&searchQuery=${encodeURI(value)}`;
				this.callSaytEndpoint(url, currTarget);
			}
		};

		// Setup search button click
		searches.forEach((search) => {
			let btnSearch = search.querySelector('.js-searchbutton');
			let searchInput = search.querySelector('.js-searchbox');
			//attach keydown event on search input to trigger search as you type

			if(searchInput) {
				searchInput.addEventListener('keyup', loadSayt, false);
			}

			//attach click listeners to our search button
			if(clickEventListenerBound) {
				btnSearch.removeEventListener('click', searchClickEvent, false);
				clickEventListenerBound = false;
			}
			btnSearch.addEventListener('click', searchClickEvent, false);
		});
	}

	async loadSearch(parentContainer) {
		let searchQuery = parentContainer.querySelector('input[type="search"]').value;
		let resultsUrl = parentContainer.querySelector('input[data-sf-role="resultsUrl"]').value;
		let indexCatalogue = parentContainer.querySelector('input[data-sf-role="indexCatalogue"]').value;
		let wordsMode = parentContainer.querySelector('input[data-sf-role="wordsMode"]').value;

		// Parse out first 2 segments, which will be the site and leading /
		let segments = resultsUrl.split('/');
		segments.splice(0, 2);

		// Strip out language, if included in url
		if (this.lang && segments[0] && segments[0].toLowerCase() === this.lang.toLowerCase()) {
			segments.splice(0, 1);
		}

		let resultsRoute = segments.join('/');

		// Build API url
		const url = `${this.apiurl}/api/search?site=${this.site}&language=${this.lang}&path=${resultsRoute}&indexCatalogue=${indexCatalogue}&wordsMode=${wordsMode}&searchQuery=${encodeURI(searchQuery)}`;

		// Call API
		await this.getAPIResults(url);
	}

	async getAPIResults(url) {
		// Show loading message
		this.showHideLoading(true);

		let response;
		try {
			response = await fetch(url, {
				headers: {
					"X-ApiKey": this.apikey
				},
			});
			let responseData = await response.json();

			if (responseData) {
				// Validate the format of this data
				// We need at minimum an `html` property, and probably a `css` and `js` property too
				// For now let's just assume it's good
				this.initRoute(responseData);
			};
		} catch(e) {
			this.handleLoadError(e);
		}
	}

	async callSaytEndpoint(url, item) {
		let $this = this;
		let response;
		try {
			response = await fetch(url, {
				headers: {
					"X-ApiKey": this.apikey
				},
			});
			let responseData = await response.json();

			if (responseData) {
				let results = responseData['suggestions'];

				let parent = $this.findParentBySelector(item, '.sf-search-input-wrapper');
				let autocompleteList = parent.querySelector('.js-autocomplete-list');

				if(results.length) {
					autocompleteList.innerHTML = "";
					results.forEach((result) => {
						var node = document.createElement("LI");
						node.classList.add('autocomplete__item');
						node.classList.add('js-sr');// Create a <li> node
						var textnode = document.createTextNode(`${result}`);         // Create a text node
						node.appendChild(textnode);                              // Append the text to <li>
						autocompleteList.appendChild(node);
					});

					let autocompleteResults = document.querySelectorAll('.js-sr');
					if(autocompleteResults) {
						autocompleteResults.forEach((result) => {
							result.addEventListener('click', () => {
								item.value = result.textContent;
								let btn = parent.querySelector('.js-searchbutton');
								btn.click();
							});
						});
						autocompleteList.classList.add('state-visible');
					}
				} else {
					autocompleteList.classList.remove('state-visible');
					autocompleteList.innerHtml('');
				}


			}
		} catch(e) {
			this.handleLoadError(e);
		}
	}

	cleanup(oldRoute, newRoute) {
		if (this.routeData.hasOwnProperty('js')) {
			//this compares the old routes included javascript (An Array)
			//with the old routes included javascript (An Array)
			let scriptUrl;

			let oldRoutesJS = oldRoute.js;
			let newRoutesJS = newRoute.js;

			if(oldRoutesJS.length && newRoutesJS.length) {
				// console.log(oldRoutesJS);
				// console.log(newRoutesJS);
				oldRoutesJS.forEach((prevJS) => {
					//All javascript has a cachebust param added which means no javascript will be the same
					//from one view to the next so we loop through our old javascript and destroy/unload it.
					const nextModule = SfModuleRegistry.get('js', prevJS);
					let module = nextModule.getModuleRef();
					if(module) {
						module.destroy();
					}
				});
			}
		}
	}

	collectionHas(a, b) { //helper function (see below)
		for(let i = 0, len = a.length; i < len; i ++) {
			if(a[i] == b) return true;
		}
		return false;
	}

	findParentBySelector(elm, selector) {
		let $this = this;
		let all = document.querySelectorAll(selector);
		let cur = elm.parentNode;

		while(cur && !$this.collectionHas(all, cur)) { //keep going up until you find a match
			cur = cur.parentNode; //go up
		}

		return cur; //will return null if not found
	}

	//TAGS INITIALIZE
    initializeTags() {
		let $this = this;
	    let dropdownMenus = document.querySelectorAll('.js-tags-filter');

	    if(!dropdownMenus.length) {
		    return;
	    }
	    //SETTING UP OUR CLICK HANDLER ON TAGS
	    dropdownMenus.forEach((menu) => {
		    let trigger = menu.querySelector('.js-tags-selected-item');
		    let dropdownLinks = menu.querySelectorAll('.dropdown-filter__link');
		    let list = menu.querySelector('.dropdown-filter__list');

		    trigger.addEventListener('click', $this.dropdownClickHandler, false);

		    //functions checks if were in a tags page, if so set the active element and append clear tag options
		    if(window.location.href.indexOf("-in-tags") > -1) {
			    let preSwtichUrl = window.location.href.split('-in-tags')[0];
			    let clearTagsUrl;
			    let activeTag = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
			    let matchingLink;

			    if(preSwtichUrl && preSwtichUrl.includes('#sf:')) {
			    	preSwtichUrl =  preSwtichUrl.split('#sf:')[1];
			    	clearTagsUrl =  '#sf:' + preSwtichUrl;
			    }

			    dropdownLinks.forEach((link) => {
				    let theHref = link.getAttribute('href');
				    if(theHref){
					    matchingLink = theHref.substring(theHref.lastIndexOf('/') + 1);
				    }

				    if(activeTag === matchingLink) {
					    trigger.innerHTML = link.textContent;
				    }
			    });

			    //append clear tags
			    let appendClearHtml = `<li><a class="js-clear-tags dropdown-filter__clear-tags" href="${clearTagsUrl}">X Clear Tag</a></li>`;
			    list.insertAdjacentHTML('beforeend', appendClearHtml);
		    }
	    });

	    //SETS UP A CLOSE EVENT WHEN CLICKED OUTSIDE OF DROPMENU
	    document.addEventListener('click', $this.closeTagsDropdownOutsideOfClick, false);
    }


    initializeCategories() {
		let $this = this;
	    let dropdownMenus = document.querySelectorAll('.js-categories-filter');

	    if(!dropdownMenus.length) {
	    	return;
	    }

	    dropdownMenus.forEach((menu) => {
		    let trigger = menu.querySelector('.js-categories-selected-item');
		    let dropdownLinks = menu.querySelectorAll('.dropdown-filter__link');
		    let list = menu.querySelector('.dropdown-filter__list');

		    trigger.addEventListener('click', $this.dropdownClickHandler, false);
		    //functions checks if were in a categories page, if so set the active element and append clear category options
		    if(window.location.href.indexOf("-in-category") > -1) {
			    let preSwtichUrl = window.location.href.split('-in-category')[0];
			    let clearCategoriesUrl;
			    let activeTag = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
			    let matchingLink;

			    if(preSwtichUrl && preSwtichUrl.includes('#sf:')) {
				    preSwtichUrl =  preSwtichUrl.split('#sf:')[1];
				    clearCategoriesUrl =  '#sf:' + preSwtichUrl;
			    }

			    dropdownLinks.forEach((link) => {
				    let theHref = link.getAttribute('href');
				    if(theHref){
					    matchingLink = theHref.substring(theHref.lastIndexOf('/') + 1);
				    }

				    if(activeTag === matchingLink) {
					    trigger.innerHTML = link.textContent;
				    }
			    });

			    //append clear tags
			    let appendClearHtml = `<li><a class="js-clear-categories dropdown-filter__clear-tags" href="${clearCategoriesUrl}">X Clear Category</a></li>`;
			    list.insertAdjacentHTML('beforeend', appendClearHtml);

		    }
	    });

	    document.addEventListener('click', $this.closeCategoriesDropdownOutsideOfClick, false);
    }

    dropdownClickHandler(evt) {
	    if(evt.currentTarget.parentNode.classList.contains('state-open')) {
		    evt.currentTarget.parentElement.classList.remove('state-open');
	    } else {
		    evt.currentTarget.parentElement.classList.add('state-open');
	    }
    }

	closeTagsDropdownOutsideOfClick = (evt) => {
		let specifiedElement = document.querySelector('.js-tags-filter');
		let isClickInside = false;

		if(specifiedElement) {
			isClickInside = specifiedElement.contains(evt.target);
		}

		if (!isClickInside) {
			specifiedElement.classList.remove('state-open');
		}
	};

	closeCategoriesDropdownOutsideOfClick = (evt) => {
		let specifiedElement = document.querySelector('.js-categories-filter');

		let isClickInside = false;

		if(specifiedElement) {
			isClickInside = specifiedElement.contains(evt.target);
		}

		if (!isClickInside) {
			specifiedElement.classList.remove('state-open');
		}
	};


}

window.SfModuleLoader = SfModuleLoader;
